import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../dialogs.scss';

export default function UPlayer(props) {

    const {open, close} = props;


    function redirectToWeb() {
        window.location.href = "https://uplayer.ugiat.com/";
    }

    return (
        <Dialog
            fullWidth={false}
            maxWidth={"md"}
            open={open}
            onClose={() => close()}
        >
            <DialogContent>
                <div className='dialog-content-root'>
                    <div className='title'>uPlayer</div>
                    <div className='img'>
                        <img src="./imgs/products/more/uplayer_navigation.gif" alt="uplayer" />
                    </div>
                    <div className='text'>
                        Videos are hierarchically decomposed in comprehensible temporal units: scenes, shots 
                        and audio/video frames. Then, each unit is summarized by a set of valuable metadata. For instance, 
                        the most important actors are facially recognized, global locations where the action takes 
                        place are described, objects found in the image are detected, perceived quality of the 
                        photography is scored and more.
                        <br/>
                        uPlayer is a smart player that can manage and present all these high-level metadata extracted. It allows
                        advanced search over the video content to find intervals where a determined person has been recognized,
                        segment the video into the highest-level semantic units, navigate through the textual metadata (OCRs and subtitles) 
                        and other relevant functionalities.
                        <br/>
                        It is presented as a chrome extension that extend the Youtube player by adding the respective buttons to perfom all
                        these functionalities. The extracted metadata of the target video are previously analyzed and sent to plugin.
                        uPlayer uses multiple artificial intelligence models, each one of them fine-tunned to perform the best in each one of the 
                        different tasks.
                    </div>
                    <div className='button-container'>
                        <button onClick={redirectToWeb}>Go to uPlayer</button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}