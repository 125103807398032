import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../dialogs.scss';

export default function VirtualMarket(props) {

    const {open, close} = props;

    function redirectToWebVM() {
        window.location.href = "https://vmpwa.ugiat.com";
    }

    function redirectToWebPeixateria() {
        window.location.href = "https://peixateria.ugiat.com";
    }

    return (
        <Dialog
            fullWidth={false}
            maxWidth={"md"}
            open={open}
            onClose={() => close()}
        >
            <DialogContent>
                <div className='dialog-content-root'>
                    <div className='title'>VirtualMarket</div>
                    <div className='img'>
                        <img src="./imgs/products/more/virtualmarket.PNG" alt="uplayer" />
                    </div>

                    <div className='text'>
                        VirtualMarket allows you to visualize the products in a store and buy exactly what you want in real time. It uses multiple cameras 
                        and an object detection AI model to detect all the different products. You can click on the different products and buy them from
                        the comfort of your home.
                    </div>
                    <div className='button-container'>
                        <button onClick={redirectToWebVM}>Go to VirtualMarket</button>
                        <button onClick={redirectToWebPeixateria}>Go to Live FishMarket</button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}