import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../dialogs.scss';

export default function TVMonitoring(props) {

    const {open, close} = props;

    return (
        <Dialog
            fullWidth={false}
            maxWidth={"md"}
            open={open}
            onClose={() => close()}
        >
            <DialogContent>
                <div className='dialog-content-root'>
                    <div className='title'>Video Content Monitoring</div>
                    <div className='img'>
                        <img src="./imgs/products/more/tv_monitoring.png" alt="Video Content Monitoring" />
                    </div>
                    <div className='text'>
                        Ugiat has developed different technologies to monitor video content and quality. 
                        Video content monitoring refers to the detection of some special events within the video as, for instance, the detection of signers in some TV programs. 
                        Whereas video quality it is related with the detection of artifacts or other issues regarding the video coding and transmission processes.
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}