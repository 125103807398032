import React from 'react';
import Header from "./components/header/Header";
import Menu from "./components/menu/Menu";
import Video from "./components/video/Video";
import Services from "./components/services/Services";
import Products from "./components/products/Products";
import Contact from "./components/contact/Contact";
import Trust from "./components/trust/Trust";
import Footer from "./components/footer/Footer";
import CookieConsent from "react-cookie-consent";

function App() {
	return (
		<div className="App">
			<Header />
			<Menu />
			<Video />
			<Services />
			<Products />
			<Trust />
			<Contact />
			<Footer />
			<CookieConsent
				location="bottom"
				buttonText="Aceptar"
				style={{ background: "#2B373B" }}
			>
				Esta página web utiliza cookies para mejorar la experiencia de usuario.
			</CookieConsent>
		</div>
	);
}

export default App;
