import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../dialogs.scss';

export default function SmartAddInsertion(props) {

    const {open, close} = props;

    function redirectToWeb() {
        window.location.href = "https://smards.ugiat.com/";
    }

    return (
        <Dialog
            fullWidth={false}
            maxWidth={"md"}
            open={open}
            onClose={() => close()}
        >
            <DialogContent>
                <div className='dialog-content-root'>
                    <div className='title'>SMART ADD INSERTION</div>
                    <div className='img'>
                        <img src="./imgs/products/more/smards.png" alt="smards" />
                    </div>
                    <div className='text'>
                        smArDS is an advanced audio-visual tool that provides the best time and place to insert publicity 
                        in entertainment videos. A software specially designed for increasing the number of insertion points 
                        in video streaming services while maintaining your Video Ad Completion Rates.
                        <br/>
                        It also allows the categorization of video content into multiple advertising categories to perform the most
                        suitable ad insertion in reference to the video content.
                    </div>
                    <div className='button-container'>
                        <button onClick={redirectToWeb}>Go to smArDS</button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}