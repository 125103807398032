import React from 'react';
import './Header.scss';

export default function Header() {

	// function buttonHandler() {
	// 	const el = document.querySelector(".contact-root");
	// 	const pixels = el.getBoundingClientRect().top + window.pageYOffset - 50;
	// 	window.scrollTo({top: pixels, behavior: 'smooth'});
	// }

	return (
		<div 
			className='header-root'
			style={{backgroundImage: 'url("./imgs/header/banner.jpg")'}}
		>
			{/* <div className='wrapper'>
				<button onClick={buttonHandler}>Contact us</button>
			</div> */}
		</div>
	);

}