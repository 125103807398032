import React from 'react';
import './Trust.scss';

export default function Services() {

	return (
		<div className='trust-root'>
			<div className='title'>
				<div>Who already trust us</div>
				{/* <div className='description'>We would love to see your logo here!</div> */}
			</div>
			<div className="slider">
				<div className="slide-track">
					<div className="slide">
						<img src="./imgs/trust/adtel.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/atresmediaTV.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/geca.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/mediapro.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/rtve.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/tappx.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/tecnocim.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/telefonica.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/UPC.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/tv3.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/wayra.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/adtel.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/atresmediaTV.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/geca.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/mediapro.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/rtve.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/tappx.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/tecnocim.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/telefonica.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/UPC.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/tv3.png" height="100" width="250" alt="" />
					</div>
					<div className="slide">
						<img src="./imgs/trust/wayra.png" height="100" width="250" alt="" />
					</div>
				</div>
			</div>
		</div>
	);

}