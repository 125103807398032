import React from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import axios from 'axios';
import './Contact.scss';

export default function Contact() {

	const [disabled, setDisabled] = React.useState(false);
	const [form, setForm] = React.useState({
		name: '',
		email: '',
		phone: '',
		message: '',
		errors: {
			name: false,
			email: false,
			phone: false,
			message: false
		}
	});

	function sendMessage() {
		setForm({
			...form,
			errors: {
				name: form.name === '',
				email: form.email === '',
				phone: form.phone === '',
				message: form.message === ''
			}
		});
		if(form.name !== '' && form.email !== '' && form.phone !== '' && form.message !== '') {
			setDisabled(true);
			axios.post('/mail', form);
		}
	}

	function updateForm(key, value) {
		if(!disabled) {
			setForm({...form, [key]: value});
		}
	}

	return (
		<div className='contact-root'>
			<div className='slope'>
				<img draggable="false" src="./imgs/contact/slope.png" alt="" />
			</div>
			<div 
				className='content'
				style={{backgroundImage: 'url("./imgs/contact/background.png")'}}
			>
				<div className='text'>LET'S GET IN TOUCH!</div>
				<div className='text-secondary'>
					Do no hesitate to contact us to test our solution or to share new ideas. 
					Send us an email and we will get back to you as soon as possible!
				</div>
				{/* <div className='mail'>ugiat@ugiat.com</div> */}
				<div className='form'>
					<TextField
						label="Name"
						variant="outlined"
						style={{ marginTop: 11 }}
						value={form.name}
						onChange={e => updateForm('name', e.target.value)}
						error={form.errors.name}
						helperText={form.errors.name ? 'This field can not be empty' : ''}
					/>
					<TextField
						label="Email"
						variant="outlined"
						style={{ marginTop: 11 }}
						value={form.email}
						onChange={e => updateForm('email', e.target.value)}
						error={form.errors.email}
						helperText={form.errors.email ? 'This field can not be empty' : ''}
					/>
					<TextField
						label="Phone"
						variant="outlined"
						style={{ marginTop: 11 }}
						value={form.phone}
						onChange={e => updateForm('phone', e.target.value)}
						error={form.errors.phone}
						helperText={form.errors.phone ? 'This field can not be empty' : ''}
					/>
					<TextField
						label="Message"
						variant="outlined"
						style={{ marginTop: 11 }}
						value={form.message}
						onChange={e => updateForm('message', e.target.value)}
						multiline
						maxRows={10}
						rows={3}
						error={form.errors.message}
						helperText={form.errors.message ? 'This field can not be empty' : ''}
					/>
					<Button 
						variant="outlined"
						onClick={sendMessage}
						disabled={disabled}
					>{!disabled ? "Send Message" : "Message Sent"}</Button>
				</div>
			</div>
		</div>
	);

}