import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../dialogs.scss';

export default function Doblai(props) {

    const {open, close} = props;

    function redirectToWeb() {
        window.location.href = "https://doblai.ugiat.com/";
    }

    return (
        <Dialog
            fullWidth={false}
            maxWidth={"md"}
            open={open}
            onClose={() => close()}
        >
            <DialogContent>
                <div className='dialog-content-root'>
                    <div className='title'>Video Dubbing</div>
                    <div className='img'>
                        <img src="./imgs/products/more/doblai-examples.png" alt="DoblAI examples" />
                    </div>
                    <div className='text'>
                        DoblAI is a web platform that transcribes, translates and synthesizes the voices using cloned voices or default voice models. It's powered by the last 
                        advances in artificial intelligence video analysis. We are able to dubb videos in more than 12 languages.
                    </div>
                    <div className='button-container'>
                        <button onClick={redirectToWeb}>Go to DoblAI</button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}