import React from 'react';
import './Menu.scss';

export default function Header() {

	const [open, setOpen] = React.useState(false);
	const [scroll, setScroll] = React.useState("Start")
	React.useEffect(() => {
		window.addEventListener("scroll", function() {
			if(checkScrollPass(".contact-root")) {
				setScroll("Contact");
			} else if(checkScrollPass(".trust-root")) {
				setScroll("Trust");
			} else if(checkScrollPass(".products-root")) {
				setScroll("Products");
			} else if(checkScrollPass(".services-root")) {
				setScroll("Services");
			} else {
				setScroll("Start");
			}
		});
	}, []);
	
	function checkScrollPass(query) {
		const element = document.querySelector(query);
		return window.scrollY > element.offsetTop - 150;
	}

	function goToSection(element) {
		setOpen(false);
		const el = document.querySelector(element);
		const pixels = el.getBoundingClientRect().top + window.pageYOffset - 50;
		window.scrollTo({top: pixels, behavior: 'smooth'});
	}

	function reload() {
		window.location.reload();
	}

	return (
		<div className='menu-root'>
			<div className='wrapper'>
				<div className='logo'>
					<img src='./imgs/logos/ugiat.png' alt='ugiat logo' onClick={reload} />
				</div>
				<div className='list'>
					{/* <div><span className={scroll === 'Start' ? 'active' : ''} onClick={() => goToSection(".header-root")}>Start</span></div> */}
					<div><span className={scroll === 'Services' ? 'active' : ''} onClick={() => goToSection(".services-root")}>Services</span></div>
					<div><span className={scroll === 'Products' ? 'active' : ''} onClick={() => goToSection(".products-root")}>Products</span></div>
					<div><span className={scroll === 'Trust' ? 'active' : ''} onClick={() => goToSection(".trust-root")}>Company</span></div>
					<div><span className={scroll === 'Contact' ? 'active' : ''} onClick={() => goToSection(".contact-root")}>Contact us</span></div>
				</div>
				<div className='burguer' onClick={() => setOpen(!open)}>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div>
			<div className={open ? 'menu active' : 'menu'}>
				<div onClick={() => goToSection(".header-root")}><span>Start</span></div>
				<div onClick={() => goToSection(".services-root")}><span>Services</span></div>
				<div onClick={() => goToSection(".products-root")}><span>Products</span></div>
				<div onClick={() => goToSection(".trust-root")}><span>Company</span></div>
				<div onClick={() =>  goToSection(".contact-root")}><span>Contact us</span></div>
			</div>
		</div>
	);

}