import React from 'react';
import './Video.scss';

export default function Video() {

	function buttonHandler() {
		const el = document.querySelector(".services-root");
		const pixels = el.getBoundingClientRect().top + window.pageYOffset - 50;
		window.scrollTo({top: pixels, behavior: 'smooth'});
	}

	return (
		<div className='video-root'>
			<video autoPlay muted loop> 
				<source src='./videos/logo_detector.mp4' type='video/mp4' />
			</video>
			<div className='content'>
				<div className='wrapper'>
					<div className='title'>
						<span>AI based solutions</span>
						<span className='subtitle'>for media analysis</span>
					</div>
					<div className='description'>
						We pride ourselves on working hand in hand with our clients to 
						create the best solutions for their unique needs.
					</div>
					<div className='button'>
						<button onClick={buttonHandler}>Read more</button>
					</div>
				</div>
			</div>
		</div>
	);

}