import React from 'react';
import SmartAddInsertion from './dialogs/smart-add-insertion/SmartAddInsertion';
import ShowDescription from './dialogs/show-description/ShowDescription';
import './Products.scss';
import UPlayer from './dialogs/uplayer/uplayer';
import VirtualMarket from './dialogs/virtual-market/virtual-market';
import TVMonitoring from './dialogs/tv-monitoring/tv-monitoring';
import SportsHighligts from './dialogs/sports-highligts/sports-highligts';
import Doblai from './dialogs/doblai/DoblAI';

export default function Products() {

	const [index, setIndex] = React.useState(0);
	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			if(!entry.target.classList.contains("visible")) {
				entry.target.classList.toggle("visible", entry.isIntersecting);
			}
		});
	}, {
		threshold: 0
	});

	React.useEffect(() => {
		document.querySelectorAll(".products-root .card").forEach(element => {
			observer.observe(element);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	
	function handleClose() {
		setIndex(0);
	}

	return (
		<div 
			className='products-root'
			style={{backgroundImage: 'url("./imgs/products/background.png")'}}
		>
			<div className='title'>Recent projects</div>
			<div className='description'>Do you want to know more? Take a look at some of our projects:</div>

			<div className='products-list'>
				<div className='card'>
					<img draggable="false" src="./imgs/products/doblai.png" alt="DoblAI" />
					<div className='text'>Improve the accessibility of your videos by dubbing them into multiple languages using cloned voices.</div>
					<div className='button'><button onClick={() => setIndex(7)}>Learn more</button></div>
					<Doblai open={index === 7} close={handleClose} />
				</div>
				<div className='card'>
					<img draggable="false" src="./imgs/products/syntube.png" alt="show-description" />
					<div className='text'>Segmentation and Summarization of media content. Extract relevant topics and define the most suitable tags for a better indexing. </div>
					<div className='button'><button onClick={() => setIndex(1)}>Learn more</button></div>
					<ShowDescription open={index === 1} close={handleClose} />
				</div>
				<div className='card'>
					<img draggable="false" src="./imgs/products/uPlayer.png" alt="uPlayer" />
					<div className='text'>Smart video player with semantic segmentation, speaker recognition and advanced video search through the high-level metadata extracted.</div>
					<div className='button'><button onClick={() => setIndex(2)}>Learn more</button></div>
					<UPlayer open={index === 2} close={handleClose} />
				</div>
				<div className='card'>
					<img draggable="false" src="./imgs/products/virtual-market.png" alt="virtual-market" />
					<div className='text'>Visualize and buy products in real time from your home. Digitalize and recognize all the products of a supermarket.</div>
					<div className='button'><button onClick={() => setIndex(3)}>Learn more</button></div>
					<VirtualMarket open={index === 3} close={handleClose} />
				</div>
				{/* <div className='card'>
					<img draggable="false" src="./imgs/products/sports-highligts.png" alt="sports-highligts" />
					<div className='text'>Finding they best sports highlights automatically.</div>
					<div className='button'><button onClick={() => setIndex(4)}>Learn more</button></div>
					<SportsHighligts open={index === 4} close={handleClose} />
				</div> */}
				<div className='card'>
					<img draggable="false" src="./imgs/products/smart-adds.png" alt="smart-adds" />
					<div className='text'>Maintain user attention by placing ads smartly by non-interrupting video scenes and using the most relevant ads concerning the video content. </div>
					<div className='button'><button onClick={() => setIndex(5)}>Learn more</button></div>
					<SmartAddInsertion open={index === 5} close={handleClose} />
				</div>
				<div className='card'>
					<img draggable="false" src="./imgs/products/video-content-monitoring.png" alt="tv-monitoring" />
					<div className='text'>Monitoring system of video content events. Generate reports of the target metrics to ensure your quality criteria.</div>
					<div className='button'><button onClick={() => setIndex(6)}>Learn more</button></div>
					<TVMonitoring open={index === 6} close={handleClose} />
				</div>
				{/* <div className='card'>
					<img draggable="false" src="./imgs/products/reverse-engeneering.png" alt="reverse engineering" />
					<div className='text'>Reverse engineering</div>
					<div className='button'><button onClick={() => setIndex(7)}>Learn more</button></div>
					<ReverseEngineering open={index === 7} close={handleClose} />
				</div> */}
			</div>
		</div>
	);

}