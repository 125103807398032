import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import '../dialogs.scss';

export default function ShowDescription(props) {

    const {open, close} = props;

    function redirectToWeb() {
        window.location.href = "https://syntube.ugiat.com/";
    }

    return (
        <Dialog
            fullWidth={false}
            maxWidth={"md"}
            open={open}
            onClose={() => close()}
        >
            <DialogContent>
                <div className='dialog-content-root'>
                    <div className='title'>SynTube</div>
                    <div className='img'>
                        <img src="./imgs/products/more/syntube.png" alt="SynTube" />
                    </div>
                    <div className='text'>
                        SynTube allows summarization and semantic segmentation of video content. Moreover, relevant
                        topics and tags are also extracted for a better indexing or SEO positioning. It uses the most 
                        advanced deep learning architectures to perform the required synthesis for the summarization, 
                        semantic units identification, the relevant topics extraction as well as the tags classification.
                    </div>
                    <div className='button-container'>
                        <button onClick={redirectToWeb}>Go to SynTube</button>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}