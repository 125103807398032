import React from 'react';
import './Services.scss';

export default function Services() {

	const observer = new IntersectionObserver(entries => {
		entries.forEach(entry => {
			entry.target.classList.toggle("show", entry.isIntersecting);
		});
	}, {
		threshold: 0
	});

	React.useEffect(() => {
		document.querySelectorAll(".services-root .card").forEach(element => {
			observer.observe(element);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div 
			className='services-root'
			style={{backgroundImage: 'url("./imgs/services/background.jpg")'}}
		>
			<div className='title'>What do we do?</div>
			<div className='description'>
				Ugiat Technologies is a company specialized in digital image/audio processing, computer vision, media content analysis and natural language understanding solutions. 
				Thanks to our B2B model, multiple companies have made use of our services to reduce costs, automate their workflow and improve their efficiency. 
				Contrary to most companies, Ugiat Technologies provides custom software and dedicated support to companies to solve their specific problems.
			</div>
			<div className='services-list'>
				{/* <div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/aim.png" alt="object detection" /></div>
					<div className='icon-title'>Logo detection</div>
					<div className='icon-description'>
						Search for the frames and locations where certain logos appear on screen. We can work
						with pictures, videos and other media content. Logo detection is useful to monitor 
						advertisement.
					</div>
				</div> */}
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/classification.png" alt="classification" /></div>
					<div className='icon-title'>Image Classification, Segmentation and Captioning</div>
					<div className='icon-description'>
						Our technology is able to understand image content in several different manners. 
						Image classification is useful for information retrieval and content categorization. 
						For instance, visual sensitive content can be filtered by detecting terrorism, sex scenes and others relevant categories.
						Image segmentation and pattern recognition allows the automation of some manufacturing process, logos detection and recognition among others. 
						For example, it can be applied to a quality process based on image inspection where manufactured components must be segmented and evaluated.
						Image captioning deals with the description of the image content to understand the action that represents the image. 
						It is useful for monitoring some events as the detection of sign language interpretation. 
					</div>
				</div>
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/speech.png" alt="speech-to-text" /></div>
					<div className='icon-title'>Speech-To-Text, Closed Captioning and Text-To-Speech</div>
					<div className='icon-description'>
						Create automatic speech-to-text transcriptions from every audio and video format. 
						We provide a hybrid-solution using off-the-shelf libraries and third party services to obtain the best speech transcription qualities.
						Main languages are spanish, catalan and english but it can be extended to more than 40 languages.
						Generate closed captioning using custom tools to enhance the punctuation and capitalization and export 
						them to the most popular formats (srt, vtt, etc...).
						Synthesize speech from text using custom model voices or cloning the original voices. 
						A service useful for voice over or dubbing video content to multiple languages. 
					</div>
				</div>
				{/* <div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/punctuation.png" alt="puntuation" /></div>
					<div className='icon-title'>Punctuation and Capitalization</div>
					<div className='icon-description'>
						Add text punctuation and capitalization to automatically generated speech-to-text transcriptions.
					</div>
				</div> */}
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/NLP.png" alt="natural language processing" /></div>
					<div className='icon-title'>Natural Language Processing</div>
					<div className='icon-description'>
						Understanding text and spoken words in much the same way human beings can. NLP is useful for:
						named entity recognition, sentiment analysis, natural language generation, speech tagging and more.
						Some advanced applications could be summarization, semantic segmenation of the video content and the 
						detection of the most relevant topics. It is also useful for information retrieval for large databases.
					</div>
				</div>
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/face.png" alt="face recognition" /></div>
					<div className='icon-title'>Speaker Diarization and Face Recognition</div>
					<div className='icon-description'>
						Identify and find people in images, audio and videos. Find who speaks and when to monitor their
						presence/interventions, implement biometric applications using the facial or the speech recognition.
						These features allows the implementation of most advanced applications. For example, a dubbing system 
						using specific voices for the different speakers.
					</div>
				</div>
			</div>
			{/* <div className='services-list'>
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/aim.png" alt="object detection" /></div>
					<div className='icon-title'>Logo detection</div>
					<div className='icon-description'>
						Search for the frames and locations where certain logos appear on screen. We can work
						with pictures, videos and other media content. Logo detection is useful to monitor 
						advertisement.
					</div>
				</div>
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/classification.png" alt="classification" /></div>
					<div className='icon-title'>Image classification</div>
					<div className='icon-description'>
						Classify frames depending on the scene, what appears on screen and the general caracteristics 
						of the image. Image classification is useful when you are looking for a very specific scene,
						for example, if there's people playing football.
					</div>
				</div>
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/speech.png" alt="speech-to-text" /></div>
					<div className='icon-title'>Speech recognition</div>
					<div className='icon-description'>
						Create automatic speech-to-text transcriptions from every audio and video format. We currently
						offer this service in English and Spanish but we may offer more languages on request.
					</div>
				</div>
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/punctuation.png" alt="puntuation" /></div>
					<div className='icon-title'>Punctuation and Capitalization</div>
					<div className='icon-description'>
						Add text punctuation and capitalization to automatically generated speech-to-text transcriptions.
					</div>
				</div>
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/face.png" alt="face recognition" /></div>
					<div className='icon-title'>Face recognition</div>
					<div className='icon-description'>
						Identify and find people in pictures and videos.
					</div>
				</div>
				<div className='card'>
					<div className='icon'><img draggable="false" src="./imgs/services/NLP.png" alt="natural language processing" /></div>
					<div className='icon-title'>Natural Language Processing</div>
					<div className='icon-description'>
						Understanding text and spoken words in much the same way human beings can. NLP is useful for:
						named entity recognition, sentiment analysis, natural language generation, speech tagging and more.
					</div>
				</div>
			</div> */}
		</div>
	);

}